$brand-blue-color-600: #252D4E;
$brand-blue-color-500: #2D2A81;
$brand-blue-color-400: #3C38AB;

$dark-color-500: #1C1C28;
$dark-color-400: #28293D;
$dark-color-300: #555770;
$dark-color-200: #8E90A6;
$dark-color-100: #CECFDE;
$dark-color-50: #E7E7EE;

$yellow-color-500: #F3C73C;
$yellow-color-300: #FBEEC3;

$light-color-400: #CDCEDA;
$light-color-300: #DFDFE7;
$light-color-200: #E7E7EE;
$light-color-100: #F5F5F9;
$light-color-50: #FFFFFF;

$white-color: white;

$red-color-500: #E53535;
$red-color-400: #F64949;
$red-color-100: #FFDBDB;

$green-color-500: #05A660;
$green-color-100: #DCFCEC;

$purple-color-500: #4C0099;

$orange-color-500: #F28100;

$blue-color-500: #004FC4;

//______________________________________________
$active-bg-color: #E5E6F5;
$app-background-color: $light-color-100;
$border-color: $dark-color-50;

$input-text-color: $dark-color-300;
$input-border-color: $light-color-400;

$primary-font-family: 'Open Sans', sans-serif;

// shadows
$shadow4: 0px 2px 4px rgba(45, 42, 129, 0.10);
$shadow8: 0px 4px 8px rgba(45, 42, 129, 0.10);
$shadow16: 0px 6px 16px rgba(45, 42, 129, 0.15);
$shadow20: 0px 4px 20px rgba(45, 42, 129, 0.08);
$shadow4-reverse: 0px -2px 4px rgba(8, 35, 48, 0.06);

// resolution
$tablet-width: 1200px;
$mobile-width: 767px;
$small-mobile-width: 600px;

:export {
  whiteColor: $white-color;
  brandBlueColor500: $brand-blue-color-500;
  blueColor500: $blue-color-500;
  redColor400: $red-color-400;
  greenColor500: $green-color-500;
  inputTextColor: $input-text-color;
  inputBorderColor: $input-border-color;
  yellowColor500: $yellow-color-500;
  orangeColor500: $orange-color-500;
  primaryFontFamily: $primary-font-family;
  popupShadow: $shadow16;
  darkColor500: $dark-color-500;
  darkColor300: $dark-color-300;
  darkColor200: $dark-color-200;
  lightColor300: $light-color-300;
  whiteColor: $white-color;
  lightColor200: $light-color-200;
  lightColor100: $light-color-100;
  lightColor50: $light-color-50;
  activeBgColor: $active-bg-color;
  borderColor: $border-color;
  tabletWidth: $tablet-width;
  mobileWidth: $mobile-width;
  smallMobileWidth: $small-mobile-width;
  purpleColor500: $purple-color-500;
}

$maxHeightForStaticMargin: 600px;
$login-block-height: 450px;
$login-block-height-mobile: 400px;
$header-height: 83px;
$signin-block-height: 575px;

$create-password-block-height: 290px;

.create-password {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  &__continents {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/continents.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__lines {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/lines.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__title {
    color: $dark-color-500;
    @media (max-width: $mobile-width) {
      font-size: 20px!important;
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    width: 440px;
    margin-left: 15vw;
    padding: 30px;
    background-color: white;
    &.readonly {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    @media (max-width: $tablet-width) {
      margin-left: 5vw;
    }
    @media (max-width: $mobile-width) {
      width: calc(100% - 40px);
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
      .MuiOutlinedInput_large {
        .MuiOutlinedInput-input {
          height: 40px !important;
          font-size: 14px!important;
        }
      }
      .MuiInputLabel-outlined {
        font-size: 14px!important;
        transform: translate(15px, 12px) scale(1)!important;
      }
    }
    @media (max-height: $maxHeightForStaticMargin) {
      margin-top: 30px;
    }
  }
  &__form {
    width: 100%;
  }
  &__row {
    margin-top: 50px;
    @media (max-width: $mobile-width) {
      margin-top: 40px;
    }
    .MuiInputAdornment-root {
      position: absolute;
      left: auto!important;
      right: 12px;
      button {
        height: 50px!important;
        width: 50px!important;
      }
    }
  }
  &__btn-confirm {
    min-height: 50px!important;
    @media (max-width: $mobile-width) {
      font-size: 12px!important;
      min-height: 40px!important;
    }
  }
  &__error {
    margin-top: 5px !important;
  }
}
